const routes = []

routes.push({
  path: '/',
  redirect: '/home'
})

routes.push({
  path: '/',
  component: () => import('@/layouts/Auth'),
  children: [
    // login
    {
      path: 'login',
      name: 'login',
      meta: {
        title: 'Login',
        group: 'auth'
      },
      component: () => import('@/views/auth/Signin')
    },
    // login-staf
    {
      path: 'login-staff',
      name: 'login.staff',
      meta: {
        title: 'Login',
        group: 'auth'
      },
      component: () => import('@/views/auth/SigninStaff')
    },
    // register
    {
      path: 'register-customer',
      name: 'register',
      meta: {
        title: 'Register',
        group: 'auth'
      },
      component: () => import('@/views/auth/Register')
    }
  ]
})

routes.push({
  path: '/',
  component: () => import('@/layouts/Default'),
  meta: { auth: true },
  children: [
    {
      path: 'home',
      name: 'home',
      meta: {
        title: 'Home',
        group: ['banner', 'user', 'customer']
      },
      component: () => import('@/views/Home')
    },
    {
      path: 'ticket/open/',
      component: () => import('@/views/ticket/Open'),
      children: [
        {
          path: 'choose',
          name: 'open.intro',
          meta: {
            title: 'Ticket',
            group: ['user', 'customer']
          },
          component: () => import('@/views/ticket/Intro')
        },
        {
          path: 'technical/:id',
          name: 'open.technical',
          meta: {
            title: 'Ticket',
            group: ['user', 'customer']
          },
          component: () => import('@/views/ticket/open/Technical')
        },
        {
          path: 'request/:id',
          name: 'open.request',
          meta: {
            title: 'Ticket',
            group: ['user', 'customer']
          },
          component: () => import('@/views/ticket/open/Request')
        },
        {
          path: 'non-technical/:id',
          name: 'open.nontechnical',
          meta: {
            title: 'Ticket',
            group: ['user', 'customer']
          },
          component: () => import('@/views/ticket/open/NonTechnical')
        }
      ]
    },
    {
      path: 'tickets/open/qr',
      name: 'ticket.open.qr',
      props(route) {
        return route.query || {}
      },
      meta: {
        title: 'Open Ticket',
        group: ['user', 'customer']
      },
      component: () => import('@/views/ticket/OpenQr')
    },
    {
      path: 'tickets',
      name: 'ticket.index',
      props(route) {
        return route.query || {}
      },
      meta: {
        title: 'My Ticket',
        group: ['user', 'customer']
      },
      component: () => import('@/views/ticket/Index')
    },
    {
      path: 'tickets/:slug/:id',
      name: 'ticket.detail',
      props(route) {
        return route.query || {}
      },
      meta: {
        title: 'Detail Ticket',
        group: []
      },
      component: () => import('@/views/ticket/Detail')
    },
    {
      path: 'plannings',
      name: 'planning.index',
      props(route) {
        return route.query || {}
      },
      meta: {
        title: 'Planning',
        group: ['user', 'customer']
      },
      component: () => import('@/views/planning/Index')
    },
    {
      path: 'instruments',
      name: 'instrument.index',
      props(route) {
        return route.query || {}
      },
      meta: {
        title: 'Instrument',
        group: ['user', 'customer']
      },
      component: () => import('@/views/instrument/Index')
    },
    {
      path: 'contact',
      name: 'contact',
      meta: {
        title: 'Contact',
        group: []
      },
      component: () => import('@/views/Contact')
    },
    {
      path: 'broadcasts',
      name: 'broadcasts',
      meta: {
        title: 'Broadcasts',
        group: []
      },
      component: () => import('@/views/broadcast/Index')
    },
    {
      path: 'broadcast/:slug',
      name: 'broadcast.detail',
      meta: {
        title: 'Broadcasts',
        group: []
      },
      component: () => import('@/views/broadcast/Detail')
    },
    {
      path: 'profile/:slug',
      name: 'profile',
      meta: {
        title: 'Profile',
        group: []
      },
      component: () => import('@/views/Profile')
    },
    {
      path: 'notifications',
      name: 'notifications',
      meta: {
        title: 'Notifications',
        group: []
      },
      component: () => import('@/views/Notifications')
    },
    {
      path: 'helpme',
      name: 'helpme',
      meta: {
        title: 'Help Me',
        group: []
      },
      component: () => import('@/views/HelpMe')
    },
    {
      path: 'certificates',
      name: 'certificates',
      props(route) {
        return route.query || {}
      },
      meta: {
        title: 'Certificates',
        group: []
      },
      component: () => import('@/views/instrument/Certificates')
    },
    {
      path: 'certificates-training',
      name: 'certificates.training',
      props(route) {
        return route.query || {}
      },
      meta: {
        title: 'Certificates Training',
        group: ['user', 'customer']
      },
      component: () => import('@/views/instrument/CertificatesTraining')
    },
    {
      path: 'points',
      name: 'points',
      props(route) {
        return route.query || {}
      },
      meta: {
        title: 'Points',
        group: []
      },
      component: () => import('@/views/Points')
    },
    {
      path: 'caf',
      name: 'caf',
      props(route) {
        return route.query || {}
      },
      meta: {
        title: 'CAF',
        group: []
      },
      component: () => import('@/views/instrument/Caf')
    }
  ]
})

routes.push({
  path: '/email/verification',
  component: () => import('@/views/EmailVerified.vue'),
  meta: {
    title: 'Email Verified',
    group: ['']
  }
})

routes.push(
  // Always leave this as last one,
  // but you can also remove it
  {
    path: '*',
    component: () => import('@/views/Error.vue'),
    meta: {
      title: 'Error404',
      group: ['']
    }
  }
)

export default routes
