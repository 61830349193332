<template>
  <div class="fixed w-full h-full top-0 left-0 flex items-center justify-center z-30" v-if="isOpen">
    <div class="absolute w-full h-full bg-gray-900 opacity-50"></div>

    <div class="absolute w-full bottom-0 sm:inset-y-auto max-w-sm">
      <div class="relative bg-white overflow-hidden sm:rounded-28px pt-7 pb-9">
        <div class="flex justify-between items-start">
          <!--  -->
          <div class="w-full">
            <div class="flex justify-between items-center px-11">
              <div class="font-semibold text-htext">
                {{ isOpen ? isOpen.number || isOpen.certificate_number || '-' : '-' }}
              </div>
              <img :src="SvgClear" class="cursor-pointer" @click="close" />
            </div>

            <div class="grid gap-5 mx-auto mt-6" style="max-width: 295px">
              <div
                class="py-4 px-5 hb-instrument flex justify-between relative cursor-pointer"
                @click="toView(isOpen)"
              >
                <div class="flex">
                  <IconDownload class="mr-2 h-6 w-7.2" />
                  <span class="text-htext font-bold">View Certificate</span>
                </div>
                <IconArrow
                  class="absolute right-0 inset-y-auto pt-1 mr-1 h-5 w-5 stroke-current text-hblue1 justify-self-end"
                />
              </div>
              <!-- <div>
                <a
                  :href="isOpen.download"
                  download="download"
                  class="py-4 px-5 hb-instrument flex justify-between relative"
                >
                  <div class="flex">
                    <IconDownload class="mr-2 h-6 w-7.2" />
                    <span class="text-htext font-bold">Download Certificate</span>
                  </div>
                  <IconArrow
                    class="absolute right-0 inset-y-auto pt-1 mr-1 h-5 w-5 stroke-current text-hblue1 justify-self-end"
                  />
                </a>
              </div> -->
              <div
                v-if="isOpen && isOpen.label_sticker"
                class="py-4 px-5 hb-instrument flex justify-between relative cursor-pointer"
                @click="toSticker(isOpen)"
              >
                <div class="flex">
                  <IconDownload class="mr-2 h-6 w-7.2" />
                  <span class="text-htext font-bold">View Sticker</span>
                </div>
                <IconArrow
                  class="absolute right-0 inset-y-auto pt-1 mr-1 h-5 w-5 stroke-current text-hblue1 justify-self-end"
                />
              </div>
              <!-- <div
                class="py-4 px-5 hb-instrument flex justify-between relative cursor-pointer"
                @click="toView(isOpen)"
              >
                <div class="flex">
                  <IconCertificate class="mr-2 h-6 w-7.2" />
                  <span class="text-htext font-bold">View Certificate</span>
                </div>
                <IconArrow
                  class="absolute right-0 inset-y-auto pt-1 mr-1 h-5 w-5 stroke-current text-hblue1 justify-self-end"
                />
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import SvgClear from '@/assets/icons/icon-close.svg'
// import IconCertificate from '@/assets/icons/icon-certificate.svg?inline'
import IconArrow from '@/assets/icons/icon-arrow-nav.svg?inline'
import IconDownload from '@/assets/icons/icon-downloadBlue.svg?inline'

export default {
  name: 'modal-certificate',
  components: {
    // IconCertificate,
    IconArrow,
    IconDownload
  },
  data() {
    return {
      SvgClear
    }
  },

  methods: {
    ...mapActions('modal', ['MODAL_CERTIFICATE']),
    close() {
      this.MODAL_CERTIFICATE(false)
    },
    toView(val) {
      if (val.certificate.type === 'Internal') {
        window.open(
          `${process.env.VUE_APP_BASE_URL}/certificate-customer-training/${val.uuid}/download`
        )
      } else if (val.certificate.type === 'External') {
        window.open(`${process.env.VUE_APP_BASE_URL}/storage/${val.file_path}`)
      }
      window.open(val.file, '_blank')
    },
    toSticker(val) {
      window.open(val.label_sticker, '_blank')
    }
  },
  computed: {
    isOpen: {
      get: function () {
        return this.$store.state.modal.certificate
      },
      set: function (value) {
        this.MODAL_CERTIFICATE(value)
      }
    }
  }
}
</script>
