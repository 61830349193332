<template>
  <div id="app" class="relative font-Montserrat">
    <router-view></router-view>

    <modal-video />
    <modal-ticket-type />

    <modal-confirm-ticket />

    <modal-created-ticket />

    <modal-review-ticket />
    <modal-thx />

    <modal-confirm />

    <modal-upload-attachment />
    <modal-detail-attachment />

    <modal-error />

    <modal-instrument />
    <modal-certificate />
    <modal-info />
    <modal-caf />
    <modal-caf-detail />
    <modal-caf-pop />

    <ModalBot />
  </div>
</template>

<script>
import ModalConfirmTicket from '@/components/modal/ModalConfirmNewTicket'
import ModalCreatedTicket from '@/components/modal/ModalCreatedTicket'
import ModalReviewTicket from '@/components/modal/ModalReviewTicket'
import ModalThx from '@/components/modal/ModalThx'
import ModalConfirm from '@/components/modal/ModalConfirm'
import ModalError from '@/components/modal/ModalError'
import ModalUploadAttachment from '@/components/modal/ModalUploadAttachment'
import ModalDetailAttachment from '@/components/modal/ModalDetailAttachment'
import ModalInstrument from '@/components/modal/ModalInstrument'
import ModalCertificate from '@/components/modal/ModalCertificate'
import ModalTicketType from '@/components/modal/ModalTicketType'
import ModalVideo from '@/components/modal/ModalVideo'
import ModalInfo from '@/components/modal/ModalInfo'
import ModalCaf from '@/components/modal/ModalCaf'
import ModalCafDetail from '@/components/modal/ModalCafDetail'
import ModalCafPop from '@/components/modal/ModalCafPop'

import { mapActions, mapState } from 'vuex'

import TokenService from './services/tokenService'
import getDevice from './services/getDevice'
import ModalBot from './components/modal/ModalBot.vue'

let browser = getDevice.BROWSER()
let osd = getDevice.OS()

export default {
  name: 'app',
  components: {
    ModalConfirmTicket,
    ModalCreatedTicket,
    ModalReviewTicket,
    ModalThx,
    ModalConfirm,
    ModalUploadAttachment,
    ModalDetailAttachment,
    ModalInstrument,
    ModalError,
    ModalCertificate,
    ModalTicketType,
    ModalVideo,
    ModalInfo,
    ModalCaf,
    ModalCafDetail,
    ModalBot,
    ModalCafPop
  },
  computed: {
    ...mapState({
      ticket: state => state.ticket.aiTicket
    })
  },
  created() {
    try {
      if (this.$messaging) {
        this.$messaging
          .requestPermission()
          .then(() => {
            this.$messaging
              .getToken({
                vapidKey:
                  'BHxikvYaLt9Gazx6XZ0NdBcUMqeoasJJ8X_hL-VEXRYiqu_TAQ1ld4Zx9oIPOrPEfce1DN5Ghw5CS0uepldcBFE'
              })
              .then(token => {
                // console.log('token: ', token)
                this.FCM_TOKEN(token)
                this.updateFCM(token)
                this.receiveMessage()
              })
          })
          .catch(err => {
            return err
          })
      } else {
        console.log('FCM not supported')
      }
    } catch (e) {
      return e
    }
  },
  mounted() {
    this.checkConnection()
    this.checkUrl()
    const channel = new window.BroadcastChannel('sw-messages')
    channel.addEventListener('message', event => {
      let data = event.data
      if (data && data.target_page == '.ChatBotActivity') this.AI_CALLBACK(data.payload)
    })

    if (this.ticket && this.ticket.id) this.AI_HISTORY_CHAT(this.ticket.id)
  },
  methods: {
    ...mapActions('auth', ['ME', 'FCM_TOKEN', 'STORE_FCM']),
    ...mapActions('ticket', ['GET_TICKET_CHAT', 'AI_CALLBACK', 'AI_HISTORY_CHAT']),
    receiveMessage() {
      try {
        this.$messaging.onMessage(payload => {
          let jp = payload.data
          let data = jp.web ? JSON.parse(jp.web) : JSON.parse(jp.android)
          // console.log('data: ', data)

          if (data.direction == 'token') this.qrcode(data.payload.access_token)

          if (data.direction == '.ChatMessageActivity') {
            this.GET_TICKET_CHAT(data.id[0])

            this.$toast(data.title + data.payload.ticket_number, {
              position: 'top-right',
              timeout: 2000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              hideProgressBar: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              closeButton: false,
              icon: false,
              rtl: false,
              onClick: function () {
                window.location.href = data.payload.url
              }
            })
          }

          if (data.target_page == '.ChatBotActivity') {
            console.log('callback: ', data.payload)
            this.AI_CALLBACK(data.payload)
          }
        })
      } catch (e) {
        return e
      }
    },
    async qrcode(token) {
      TokenService.setAccessToken(token)
      let res = await this.ME()
      if (res) {
        setTimeout(() => {
          this.$router.push({ name: 'home' })
        }, 1000)
      }
    },
    checkConnection() {
      if (navigator.onLine) {
        // console.log('Connected to internet.')
      } else {
        this.$toast.warning('There is no internet connection.', {
          position: 'top-right',
          timeout: 10000
        })
      }
    },
    updateFCM(token) {
      let loggedIn = this.$store.state.auth.loggedIn
      let params = {}

      if (loggedIn) {
        params.fcm_token = token
        params.name = browser
        params.osd = osd
        params.device = 1
        params.timestamp = new Date().getTime()

        this.STORE_FCM(params)
      }
    },
    checkUrl() {
      let urlNew = process.env.VUE_APP_BASE_URL_NEW
      let url = window.location.origin

      // let urlNew = 'http://192.168.18.58:8080'

      if (url == process.env.VUE_APP_BASE_URL_OLD)
        window.location.replace(urlNew + '/login?rtoken=' + TokenService.getAccessToken())
    }
  }
}
</script>
